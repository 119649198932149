.fc .fc-scrollgrid-section {
  height: 0px
}

.fc a[data-navlink]:hover {
  text-decoration: none
}

.fc .fc-timegrid-slot {
  height: 2em !important
}

.fc .fc-timeGridWeek-view .fc-timegrid-slot-minor {
  border-top-style: none
}

.fc .fc-timeGridDay-view .fc-timegrid-slot-minor {
  border-top-style: solid
}

.fc .fc-col-header-cell-cushion {
  padding-top: 8px !important;
  padding-bottom: 8px !important
}

.fc .fc-toolbar {
  flex-wrap: wrap
}

.fc .fc-toolbar .fc-prev-button, .fc .fc-toolbar .fc-next-button {
  display: inline-block;
  box-shadow: none !important;
  border-radius: .375rem !important
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button) {
  padding: .438rem 1.375rem
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):active, .fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):focus {
  box-shadow: none !important
}

.fc .fc-toolbar > * > :not(:first-child) {
  //margin-left: 0 !important
}

[dir=rtl] .fc .fc-toolbar > * > :not(:first-child) {
  margin-right: 0 !important
}

.fc .fc-toolbar .fc-toolbar-chunk {
  display: flex;
  align-items: center
}

.fc .fc-toolbar .fc-button-group .fc-button {
  text-transform: capitalize
}

.fc .fc-toolbar .fc-button-group .fc-button.fc-next-button.fc-button-primary, .fc .fc-toolbar .fc-button-group .fc-button.fc-prev-button.fc-button-primary {
  background-color: rgba(0, 0, 0, 0);
  border-color: #8a8d93;
  color: #8a8d93
}

html:not([dir=rtl]) .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

html[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

html:not([dir=rtl]) .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

html[dir=rtl] .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.fc .fc-toolbar .fc-button-group + div {
  display: flex;
  align-items: center;
  flex-wrap: wrap
}

.fc .fc-toolbar .fc--button:empty, .fc .fc-toolbar .fc-toolbar-chunk:empty {
  display: none
}

.fc .fc-toolbar .fc-sidebarToggle-button + div {
  margin-left: 0
}

.fc table.fc-scrollgrid {
  margin: 0 .25rem;
  width: calc(100% - .5rem)
}

.fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
  border-left: none
}

.fc .fc-view-harness {
  min-height: 650px
}

.fc .fc-daygrid-day-events .fc-event, .fc .fc-daygrid-day-events .fc-more-link {
  margin-left: .75rem !important;
  margin-right: .75rem !important
}

.fc .fc-daygrid-block-event .fc-event-time, .fc .fc-daygrid-dot-event .fc-event-title {
  font-weight: 400
}

.fc .fc-daygrid-event-harness + .fc-daygrid-event-harness .fc-daygrid-event {
  margin-top: .625rem !important
}

.fc .fc-day-today {
  background-clip: padding-box
}

.fc .fc-h-event .fc-event-main, .fc .fc-v-event .fc-event-main {
  //color: inherit !important
}

.fc .fc-h-event .fc-event-main-frame {
  align-items: center
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.fc .fc-view-harness {
  margin: 0 -1.5rem
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top {
  flex-direction: row
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top .fc-daygrid-day-number {
  float: left;
  padding: .5rem
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-bottom .fc-daygrid-more-link {
  margin-top: .5rem
}

.fc .fc-view-harness .fc-event {
  font-size: .875rem;
  font-weight: 400;
  padding: .1rem .5rem;
  border-radius: 50rem;
  border: none
}

.fc .fc-view-harness .fc-event .fc-event-title-container {
  font-size: .875rem;
  font-weight: 400
}

.fc .fc-view-harness .fc-daygrid-event-harness .fc-event.private-event {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important
}

.fc .fc-view-harness .fc-event .fc-daygrid-event-dot {
  display: none
}

.fc .fc-timegrid .fc-timegrid-divider {
  display: none
}

.fc .fc-timegrid .fc-timegrid-event {
  border-radius: 0px;
  box-shadow: none;
  padding-top: .5rem
}

.fc .fc-timegrid .fc-timegrid-event .fc-event-time {
  font-size: inherit
}

.fc .fc-timegrid-axis-frame {
  align-items: start
}

.fc .fc-daygrid-event-harness-abs .fc-event {
  margin-bottom: .625rem
}

.fc .fc-timegrid-slot-label-frame {
  text-align: center
}

.fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
  font-size: .8125rem
}

.fc .fc-timegrid-axis-cushion {
  text-transform: capitalize;
  padding: .5rem .4375rem
}

.fc .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
  padding: .5rem !important
}

.fc .fc-list {
  margin: 0 .25rem;
  width: calc(100% - .5rem)
}

.fc .fc-list .fc-list-table .fc-list-event td {
  font-size: .9375rem;
  font-weight: 400
}

.fc .fc-list-day-cushion, .fc .fc-list-table td {
  padding-inline: 1rem
}

.fc .fc-popover {
  z-index: 1090 !important
}

.fc .fc-popover .fc-popover-header {
  padding: .566rem
}

.light-style .fc .fc-col-header-cell-cushion {
  color: #433c50
}

.light-style .fc.fc-theme-standard .fc-list-day-cushion {
  background-color: #f2f2f3 !important
}

.light-style .fc table.fc-scrollgrid {
  border-color: #e6e5e8
}

.light-style .fc table.fc-scrollgrid .fc-col-header tbody {
  border: none
}

.light-style .fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
  border-color: #e6e5e8
}

.light-style .fc table.fc-scrollgrid td {
  border-color: #e6e5e8
}

.light-style .fc .fc-timegrid-axis-cushion {
  color: #aba8b1
}

.light-style .fc .fc-timegrid-slot-label-cushion {
  color: #433c50
}

.light-style .fc .private-event .fc-event-time, .light-style .fc .private-event .fc-event-title {
  color: #ff4c51
}

.light-style .fc .fc-day-today {
  background-color: #f2f2f3 !important
}

.light-style .fc .fc-day-today .fc-popover-body {
  background-color: #fff !important
}

.light-style .fc .fc-popover .fc-popover-header {
  background: #f4f5fa
}

.light-style .fc .fc-list {
  border-color: #e6e5e8
}

.light-style .fc .fc-list .fc-list-table {
  border-bottom: 1px solid
}

.light-style .fc .fc-list .fc-list-table th {
  background: #f4f5fa
}

.light-style .fc .fc-list .fc-list-table .fc-list-event {
  cursor: pointer
}

.light-style .fc .fc-list .fc-list-table .fc-list-event:hover td {
  background-color: rgba(46, 38, 61, .025)
}

.light-style .fc .fc-list .fc-list-table .fc-list-event td {
  border-color: #e6e5e8;
  color: #6d6777
}

.light-style .fc .fc-list .fc-list-table .fc-list-day th {
  color: #433c50
}

.light-style .fc .fc-list .fc-list-table tbody > tr:first-child th {
  border-top: 1px solid #e6e5e8
}

.light-style .fc .fc-list .fc-list-empty {
  background-color: #f4f5fa
}

.light-style .fc table, .light-style .fc tbody, .light-style .fc thead, .light-style .fc tbody td {
  border-color: #e6e5e8
}

.light-style .fc-event-primary:not(.fc-list-event) {
  background-color: #ede4ff !important;
  color: #8c57ff !important
}

.light-style .fc-event-primary:not(.fc-list-event) {
  border-color: rgba(140, 87, 255, .15)
}

.light-style .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #8c57ff !important
}

.light-style .fc-event-secondary:not(.fc-list-event) {
  background-color: #ecedee !important;
  color: #8a8d93 !important
}

.light-style .fc-event-secondary:not(.fc-list-event) {
  border-color: rgba(138, 141, 147, .15)
}

.light-style .fc-event-secondary.fc-list-event .fc-list-event-dot {
  border-color: #8a8d93 !important
}

.light-style .fc-event-success:not(.fc-list-event) {
  background-color: #e4f7d6 !important;
  color: #56ca00 !important
}

.light-style .fc-event-success:not(.fc-list-event) {
  border-color: rgba(86, 202, 0, .15)
}

.light-style .fc-event-success.fc-list-event .fc-list-event-dot {
  border-color: #56ca00 !important
}

.light-style .fc-event-info:not(.fc-list-event) {
  background-color: #daf3ff !important;
  color: #16b1ff !important
}

.light-style .fc-event-info:not(.fc-list-event) {
  border-color: rgba(22, 177, 255, .15)
}

.light-style .fc-event-info.fc-list-event .fc-list-event-dot {
  border-color: #16b1ff !important
}

.light-style .fc-event-warning:not(.fc-list-event) {
  background-color: #fff3d6 !important;
  color: #ffb400 !important
}

.light-style .fc-event-warning:not(.fc-list-event) {
  border-color: rgba(255, 180, 0, .15)
}

.light-style .fc-event-warning.fc-list-event .fc-list-event-dot {
  border-color: #ffb400 !important
}

.light-style .fc-event-danger:not(.fc-list-event) {
  background-color: #ffe2e3 !important;
  color: #ff4c51 !important
}

.light-style .fc-event-danger:not(.fc-list-event) {
  border-color: rgba(255, 76, 81, .15)
}

.light-style .fc-event-danger.fc-list-event .fc-list-event-dot {
  border-color: #ff4c51 !important
}

.light-style .fc-event-light:not(.fc-list-event) {
  background-color: #fafafb !important;
  color: #dfdfe3 !important
}

.light-style .fc-event-light:not(.fc-list-event) {
  border-color: rgba(223, 223, 227, .15)
}

.light-style .fc-event-light.fc-list-event .fc-list-event-dot {
  border-color: #dfdfe3 !important
}

.light-style .fc-event-dark:not(.fc-list-event) {
  background-color: #e2e2e2 !important;
  color: #4b4b4b !important
}

.light-style .fc-event-dark:not(.fc-list-event) {
  border-color: rgba(75, 75, 75, .15)
}

.light-style .fc-event-dark.fc-list-event .fc-list-event-dot {
  border-color: #4b4b4b !important
}

.light-style .fc-event-gray:not(.fc-list-event) {
  background-color: rgba(243, 242, 243, .92) !important;
  color: rgba(46, 38, 61, .5) !important
}

.light-style .fc-event-gray:not(.fc-list-event) {
  border-color: rgba(46, 38, 61, .15)
}

.light-style .fc-event-gray.fc-list-event .fc-list-event-dot {
  border-color: rgba(46, 38, 61, .5) !important
}

.dark-style .fc .fc-col-header-cell-cushion {
  color: #d5d1ea
}

.dark-style .fc .fc-toolbar .fc-sidebarToggle-button {
  color: #fff
}

.dark-style .fc.fc-theme-standard th {
  border-color: #474360
}

.dark-style .fc.fc-theme-standard .fc-list-day-cushion {
  background-color: #3c3856
}

.dark-style .fc .fc-timegrid-axis-cushion {
  color: #7a7692
}

.dark-style .fc .fc-timegrid-slot-label-cushion {
  color: #d5d1ea
}

.dark-style .fc table.fc-scrollgrid {
  border-color: #474360
}

.dark-style .fc table.fc-scrollgrid .fc-col-header tbody {
  border: none
}

.dark-style .fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
  border-color: #474360
}

.dark-style .fc table.fc-scrollgrid td {
  border-color: #474360
}

.dark-style .fc .private-event .fc-event-time, .dark-style .fc .private-event .fc-event-title {
  color: #ff4c51
}

.dark-style .fc .fc-day-today {
  background-color: #3c3856 !important
}

.dark-style .fc .fc-day-today .fc-popover-body {
  background-color: #312d4b !important
}

.dark-style .fc .fc-divider {
  background: #474360;
  border-color: #474360
}

.dark-style .fc .fc-popover {
  background-color: #28243d;
  border: 0
}

.dark-style .fc .fc-popover .fc-popover-header {
  background-color: #46445b
}

.dark-style .fc .fc-list {
  border-color: #474360
}

.dark-style .fc .fc-list .fc-list-table th {
  background: #28243d
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event {
  cursor: pointer
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event:hover td {
  background-color: rgba(231, 227, 252, .06)
}

.dark-style .fc .fc-list .fc-list-table .fc-list-event td {
  border-color: #474360;
  color: #b0acc7
}

.dark-style .fc .fc-list .fc-list-table .fc-list-day th {
  color: #d5d1ea
}

.dark-style .fc .fc-list .fc-list-table tbody > tr:first-child th {
  border-top: 1px solid #474360
}

.dark-style .fc .fc-list .fc-list-empty {
  background-color: #28243d
}

.dark-style .fc table, .dark-style .fc .fc-timegrid-axis, .dark-style .fc tbody, .dark-style .fc thead, .dark-style .fc tbody td {
  border-color: #474360
}

.dark-style .fc-event-primary:not(.fc-list-event) {
  background-color: #383059 !important;
  color: #8c57ff !important
}

.dark-style .fc-event-primary:not(.fc-list-event) {
  border-color: rgba(140, 87, 255, .15);
  box-shadow: none
}

.dark-style .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #8c57ff !important
}

.dark-style .fc-event-secondary:not(.fc-list-event) {
  background-color: #383551 !important;
  color: #8a8d93 !important
}

.dark-style .fc-event-secondary:not(.fc-list-event) {
  border-color: rgba(138, 141, 147, .15);
  box-shadow: none
}

.dark-style .fc-event-secondary.fc-list-event .fc-list-event-dot {
  border-color: #8a8d93 !important
}

.dark-style .fc-event-success:not(.fc-list-event) {
  background-color: #343a45 !important;
  color: #56ca00 !important
}

.dark-style .fc-event-success:not(.fc-list-event) {
  border-color: rgba(86, 202, 0, .15);
  box-shadow: none
}

.dark-style .fc-event-success.fc-list-event .fc-list-event-dot {
  border-color: #56ca00 !important
}

.dark-style .fc-event-info:not(.fc-list-event) {
  background-color: #2f3859 !important;
  color: #16b1ff !important
}

.dark-style .fc-event-info:not(.fc-list-event) {
  border-color: rgba(22, 177, 255, .15);
  box-shadow: none
}

.dark-style .fc-event-info.fc-list-event .fc-list-event-dot {
  border-color: #16b1ff !important
}

.dark-style .fc-event-warning:not(.fc-list-event) {
  background-color: #413845 !important;
  color: #ffb400 !important
}

.dark-style .fc-event-warning:not(.fc-list-event) {
  border-color: rgba(255, 180, 0, .15);
  box-shadow: none
}

.dark-style .fc-event-warning.fc-list-event .fc-list-event-dot {
  border-color: #ffb400 !important
}

.dark-style .fc-event-danger:not(.fc-list-event) {
  background-color: #412f4b !important;
  color: #ff4c51 !important
}

.dark-style .fc-event-danger:not(.fc-list-event) {
  border-color: rgba(255, 76, 81, .15);
  box-shadow: none
}

.dark-style .fc-event-danger.fc-list-event .fc-list-event-dot {
  border-color: #ff4c51 !important
}

.dark-style .fc-event-light:not(.fc-list-event) {
  background-color: #332f4c !important;
  color: #46445b !important
}

.dark-style .fc-event-light:not(.fc-list-event) {
  border-color: rgba(70, 68, 91, .15);
  box-shadow: none
}

.dark-style .fc-event-light.fc-list-event .fc-list-event-dot {
  border-color: #46445b !important
}

.dark-style .fc-event-dark:not(.fc-list-event) {
  background-color: #3e3a58 !important;
  color: #d7d5ec !important
}

.dark-style .fc-event-dark:not(.fc-list-event) {
  border-color: rgba(215, 213, 236, .15);
  box-shadow: none
}

.dark-style .fc-event-dark.fc-list-event .fc-list-event-dot {
  border-color: #d7d5ec !important
}

.dark-style .fc-event-gray:not(.fc-list-event) {
  background-color: rgba(50, 46, 76, .928) !important;
  color: rgba(231, 227, 252, .1) !important
}

.dark-style .fc-event-gray:not(.fc-list-event) {
  border-color: rgba(231, 227, 252, .15);
  box-shadow: none
}

.dark-style .fc-event-gray.fc-list-event .fc-list-event-dot {
  border-color: rgba(231, 227, 252, .1) !important
}
