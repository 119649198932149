/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #f4f5fa;
}

a:not(disabled) {
  cursor: pointer;
}

.ps__rail-x, .ps__rail-y {
  z-index: 1;
}

html, body { height: 100%; }
body {
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  ::selection{
    background-color: #8c57ff;
    color: #fff;
  }
  .app-brand-logo {
    .logo {
      height: 3rem;
      width: auto;
    }
  }

}
