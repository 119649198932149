body {
  background: #f4f5fa
}

.bg-body {
  background: #f4f5fa !important
}

.text-primary {
  color: #8c57ff !important
}

.text-body[href]:hover, .text-heading[href]:hover {
  color: #7e4ee6 !important
}

.bg-primary {
  background-color: #8c57ff !important
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #8553f2 !important
}

.bg-label-primary {
  background-color: #ede4ff !important;
  color: #8c57ff !important
}

.bg-label-hover-primary {
  background-color: #ede4ff !important;
  color: #8c57ff !important
}

.bg-label-hover-primary:hover {
  background-color: #8c57ff !important;
  color: #fff !important
}

.bg-gradient-primary {
  background-image: linear-gradient(45deg, #8c57ff, #c6abff) !important
}

.page-item .page-link:hover, .page-item .page-link:focus, .pagination li > a:not(.page-link):hover, .pagination li > a:not(.page-link):focus {
  background-color: #ede4ff;
  color: #8c57ff
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus, .page-item.active .page-link:disabled, .page-item.active .page-link.disabled, .pagination li.active > a:not(.page-link), .pagination li.active > a:not(.page-link):hover, .pagination li.active > a:not(.page-link):focus, .pagination li.active > a:not(.page-link):disabled, .pagination li.active > a:not(.page-link).disabled {
  border-color: #8c57ff;
  background-color: #8c57ff;
  color: #fff
}

.page-item .page-link.waves-effect .waves-ripple, .pagination li > a:not(.page-link).waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(46, 38, 61, 0) 70%)
}

.pagination-outline-primary .page-item.active .page-link, .pagination-outline-primary .page-item.active .page-link:hover, .pagination-outline-primary .page-item.active .page-link:focus, .pagination-outline-primary.pagination li.active > a:not(.page-link), .pagination-outline-primary.pagination li.active > a:not(.page-link):hover, .pagination-outline-primary.pagination li.active > a:not(.page-link):focus {
  border-color: #8c57ff !important;
  color: #8c57ff !important;
  background-color: #ede4ff !important
}

.pagination-outline-primary .page-item.active .page-link.waves-effect .waves-ripple, .pagination-outline-primary.pagination li.active > a:not(.page-link).waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(46, 38, 61, 0) 70%)
}

.modal-onboarding .carousel-indicators [data-bs-target] {
  background-color: #8c57ff
}

.carousel-control-prev, .carousel-control-next {
  color: #8c57ff
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #8c57ff
}

.list-group-item-primary {
  border-color: #9868ff;
  background-color: #f4eeff;
  color: #7e4ee6 !important
}

a.list-group-item-primary, button.list-group-item-primary {
  color: #7e4ee6
}

a.list-group-item-primary.active, button.list-group-item-primary.active {
  border-color: #8c57ff !important;
  background-color: #8c57ff !important;
  color: #fff !important
}

.list-group-item.active {
  background-color: #ede4ff;
  color: #8c57ff
}

.list-group-item.active.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(46, 38, 61, 0) 70%)
}

.list-group.list-group-timeline .list-group-timeline-primary:before {
  border-color: #8c57ff;
  background-color: #8c57ff
}

.alert-primary {
  background-color: #ede4ff;
  border-color: #ede4ff;
  color: #8c57ff
}

.alert-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238c57ff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-primary .alert-link {
  color: #8c57ff
}

.alert-primary hr {
  background-color: #8c57ff !important
}

.alert-primary .alert-icon {
  background-color: #8c57ff
}

.alert-outline-primary {
  border-color: #8c57ff;
  color: #8c57ff
}

.alert-outline-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238c57ff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-outline-primary .alert-link {
  color: #8c57ff
}

.alert-outline-primary hr {
  background-color: #8c57ff !important
}

.alert-outline-primary .alert-icon {
  color: #8c57ff !important;
  background-color: #ede4ff !important
}

.alert-solid-primary {
  background-color: #8c57ff;
  color: #fff
}

.alert-solid-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-solid-primary .alert-link {
  color: #fff
}

.alert-solid-primary hr {
  background-color: #fff !important
}

.alert-solid-primary .alert-icon {
  color: #8c57ff !important
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner {
  background: #8c57ff;
  color: #fff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before {
  border-top-color: #8c57ff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
  border-left-color: #8c57ff
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
  border-right-color: #8c57ff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before {
  border-bottom-color: #8c57ff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
  border-right-color: #8c57ff
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
  border-left-color: #8c57ff
}

.popover-primary, .popover-primary > .popover {
  border-color: rgba(0, 0, 0, 0);
  background: #8c57ff
}

.popover-primary .popover-header, .popover-primary > .popover .popover-header {
  border-color: rgba(255, 255, 255, .2);
  background: rgba(0, 0, 0, 0);
  color: #fff
}

.popover-primary .popover-body, .popover-primary > .popover .popover-body {
  background: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, .8)
}

.popover-primary > .popover-arrow::before, .popover-primary > .popover > .popover-arrow::before {
  border-color: rgba(0, 0, 0, 0)
}

.popover-primary.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after {
  border-top-color: #8c57ff !important
}

.popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
  border-right-color: #8c57ff !important
}

[dir=rtl] .popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, [dir=rtl] .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
  border-left-color: #8c57ff !important
}

.popover-primary.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after {
  border-bottom-color: #8c57ff !important
}

.popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
  border-left-color: #8c57ff !important
}

[dir=rtl] .popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, [dir=rtl] .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
  border-right-color: #8c57ff !important
}

.popover-primary.bs-popover-auto[data-popper-placement=bottom] .popover-header::before, .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] .popover-header::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important
}

.table-primary {
  --bs-table-bg: #e8ddff;
  --bs-table-striped-bg: #e1d6f7;
  --bs-table-striped-color: #2e263d;
  --bs-table-active-bg: #d9ceef;
  --bs-table-active-color: #2e263d;
  --bs-table-hover-bg: #ddd2f3;
  --bs-table-hover-color: #2e263d;
  color: #2e263d;
  border-color: #d2c7e8
}

.table-primary .btn-icon, .table-primary .btn {
  color: #2e263d
}

.btn-primary {
  color: #fff;
  background-color: #8c57ff;
  border-color: #8c57ff
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #7e4ee6 !important;
  border-color: #7e4ee6 !important
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #7e4ee6;
  border-color: #7e4ee6
}

.btn-primary:disabled, .btn-primary.disabled {
  background: #8c57ff !important;
  border-color: #8c57ff !important
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show.dropdown-toggle, .show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #7e4ee6 !important;
  border-color: #7e4ee6 !important
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff !important;
  background-color: #8c57ff !important;
  border-color: #8c57ff !important
}

.btn-group .btn-primary, .input-group .btn-primary {
  border-right: 1px solid #7e4ee6;
  border-left: 1px solid #7e4ee6
}

.btn-group-vertical .btn-primary {
  border-top: 1px solid #7e4ee6;
  border-bottom: 1px solid #7e4ee6
}

.btn-label-primary {
  color: #8c57ff;
  border-color: #ede4ff;
  background: #ede4ff
}

.btn-label-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-label-primary:hover, .btn-label-primary:focus, .btn-label-primary.focus, .btn-label-primary:active, .btn-label-primary.active {
  border-color: #e3d7ff !important;
  background: #e3d7ff !important;
  color: #8c57ff !important
}

.btn-label-primary:disabled, .btn-label-primary.disabled {
  background: #ede4ff !important;
  color: #8c57ff;
  border-color: #ede4ff !important
}

.btn-label-primary.show.dropdown-toggle, .show > .btn-label-primary.dropdown-toggle {
  color: #8c57ff !important;
  background-color: #ede4ff !important;
  border-color: #ede4ff !important
}

.btn-label-primary.disabled, .btn-label-primary:disabled {
  color: #8c57ff !important;
  border-color: rgba(0, 0, 0, 0) !important;
  background: #ede4ff !important
}

.btn-group .btn-label-primary, .input-group .btn-label-primary {
  border-right: 1px solid #dac9ff !important;
  border-left: 1px solid #dac9ff !important
}

.btn-group-vertical .btn-label-primary {
  border-top: 1px solid #dac9ff !important;
  border-bottom: 1px solid #dac9ff !important
}

.btn-text-primary {
  color: #8c57ff
}

.btn-text-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-text-primary:hover {
  border-color: rgba(0, 0, 0, 0);
  background: #f6f2ff;
  color: #8c57ff
}

.btn-text-primary:focus, .btn-text-primary.focus {
  color: #8c57ff;
  background: #f6f2ff
}

.btn-text-primary:disabled, .btn-text-primary.disabled {
  color: #8c57ff
}

.btn-text-primary.btn:active, .btn-text-primary.btn.active, .btn-text-primary.btn.show.dropdown-toggle, .btn.show > .btn-text-primary.dropdown-toggle {
  color: #8c57ff;
  background: #f6f2ff !important;
  border-color: rgba(0, 0, 0, 0) !important
}

.btn-group .btn-text-primary, .input-group .btn-text-primary {
  border-right: 1px solid #8c57ff !important;
  border-left: 1px solid #8c57ff !important
}

.btn-group-vertical .btn-text-primary {
  border-top: 1px solid #8c57ff !important;
  border-bottom: 1px solid #8c57ff !important
}

.btn-outline-primary, .btn-outline-primary.fc-prev-button, .btn-outline-primary.fc-next-button {
  color: #8c57ff;
  border-color: #8c57ff;
  background: rgba(0, 0, 0, 0)
}

.btn-outline-primary.waves-effect .waves-ripple, .btn-outline-primary.fc-prev-button.waves-effect .waves-ripple, .btn-outline-primary.fc-next-button.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-outline-primary:hover, .btn-outline-primary.fc-prev-button:hover, .btn-outline-primary.fc-next-button:hover {
  color: #8c57ff !important;
  background-color: #f6f2ff !important;
  border-color: #8c57ff !important
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus, .btn-check:focus + .btn-outline-primary.fc-prev-button, .btn-outline-primary.fc-prev-button:focus, .btn-check:focus + .btn-outline-primary.fc-next-button, .btn-outline-primary.fc-next-button:focus {
  color: #8c57ff;
  background-color: #f6f2ff;
  border-color: #8c57ff
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled, .btn-outline-primary.fc-prev-button:disabled, .btn-outline-primary.fc-prev-button.disabled, .btn-outline-primary.fc-next-button:disabled, .btn-outline-primary.fc-next-button.disabled {
  color: #8c57ff;
  border-color: #8c57ff
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-check:checked + .btn-outline-primary.fc-prev-button, .btn-check:active + .btn-outline-primary.fc-prev-button, .btn-outline-primary.fc-prev-button:active, .btn-outline-primary.fc-prev-button.active, .btn-outline-primary.fc-prev-button.dropdown-toggle.show, .btn-check:checked + .btn-outline-primary.fc-next-button, .btn-check:active + .btn-outline-primary.fc-next-button, .btn-outline-primary.fc-next-button:active, .btn-outline-primary.fc-next-button.active, .btn-outline-primary.fc-next-button.dropdown-toggle.show {
  color: #8c57ff !important;
  background-color: #f6f2ff !important;
  border-color: #8c57ff !important
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled, .btn-outline-primary.fc-prev-button.disabled, .btn-outline-primary.fc-prev-button:disabled, .btn-outline-primary.fc-next-button.disabled, .btn-outline-primary.fc-next-button:disabled {
  color: #8c57ff !important;
  background-color: rgba(0, 0, 0, 0) !important
}

.btn-outline-primary .badge {
  background: #8c57ff;
  border-color: #8c57ff;
  color: #fff
}

.btn-outline-primary:hover .badge, .btn-outline-primary:focus:hover .badge, .btn-outline-primary:active .badge, .btn-outline-primary.active .badge, .show > .btn-outline-primary.dropdown-toggle .badge {
  background: #8c57ff;
  border-color: #8c57ff;
  color: #fff
}

.dropdown-item.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active {
  background-color: #ede4ff;
  color: #8c57ff !important
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #ede4ff;
  color: #8c57ff
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active.btn, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item).btn {
  color: #2e263d
}

.nav .nav-link:hover, .nav .nav-link:focus {
  color: #7e4ee6
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #8c57ff;
  color: #fff
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
  color: #8c57ff
}

.nav-tabs .nav-link.waves-effect .waves-ripple {
  background: radial-gradient(rgba(140, 87, 255, 0.2) 0, rgba(140, 87, 255, 0.3) 40%, rgba(140, 87, 255, 0.4) 50%, rgba(140, 87, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.nav-tabs .tab-slider {
  background-color: #8c57ff
}

.form-control:focus, .form-select:focus {
  border-color: #8c57ff !important
}

.form-floating-outline .form-control:focus, .form-floating-outline .form-select:focus {
  border-color: #8c57ff !important
}

.input-group:not(.input-group-floating):focus-within .form-control, .input-group:not(.input-group-floating):focus-within .input-group-text {
  border-color: #8c57ff
}

.form-check-input:hover::after {
  background: rgba(46, 38, 61, .06)
}

.form-check-input:checked {
  background-color: #8c57ff;
  border-color: #8c57ff
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #8c57ff;
  border-color: #8c57ff
}

.custom-option.checked {
  border: 1px solid #8c57ff
}

.custom-option.checked .custom-option-body i, .custom-option.checked .custom-option-header i {
  color: #8c57ff
}

.custom-option.custom-option-label.checked {
  background-color: rgba(140, 87, 255, .08);
  color: #8c57ff
}

.custom-option.custom-option-label.checked .custom-option-header span, .custom-option.custom-option-label.checked .custom-option-title {
  color: #8c57ff
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url%28%23a%29'%3e%3ccircle cx='12' cy='11' r='8.5' fill='%23fff'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='a' x='0' y='0' width='22' height='22' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='2'/%3e%3cfeGaussianBlur stdDeviation='2'/%3e%3cfeColorMatrix values='0 0 0 0 0.180392 0 0 0 0 0.14902 0 0 0 0 0.239216 0 0 0 0.16 0'/%3e%3cfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_6488_3264'/%3e%3cfeBlend in='SourceGraphic' in2='effect1_dropShadow_6488_3264' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url%28%23a%29'%3e%3ccircle cx='12' cy='11' r='8.5' fill='%23fff'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='a' x='0' y='0' width='22' height='22' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='2'/%3e%3cfeGaussianBlur stdDeviation='2'/%3e%3cfeColorMatrix values='0 0 0 0 0.180392 0 0 0 0 0.14902 0 0 0 0 0.239216 0 0 0 0.16 0'/%3e%3cfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_6488_3264'/%3e%3cfeBlend in='SourceGraphic' in2='effect1_dropShadow_6488_3264' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e")
}

.form-control:focus ~ .form-label {
  border-color: #8c57ff
}

.form-control:focus ~ .form-label::after {
  border-color: inherit
}

.form-range::-webkit-slider-thumb {
  background-color: #8c57ff
}

.form-range::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(140, 87, 255, .15), 0px 3px 14px 0px rgba(46, 38, 61, .14)
}

.form-range::-webkit-slider-thumb:active {
  background-color: #8c57ff;
  box-shadow: 0 0 0 10px rgba(140, 87, 255, .2), 0px 3px 14px 0px rgba(46, 38, 61, .14)
}

.form-range::-moz-range-thumb:hover {
  box-shadow: 0 0 0 8px rgba(140, 87, 255, .15), 0px 3px 14px 0px rgba(46, 38, 61, .14)
}

.form-range::-moz-range-thumb:active {
  box-shadow: 0 0 0 10px rgba(140, 87, 255, .2), 0px 3px 14px 0px rgba(46, 38, 61, .14)
}

.form-range::-webkit-slider-runnable-track {
  background-color: #8c57ff
}

.form-range::-moz-range-track {
  background-color: #8c57ff
}

.switch-input:checked ~ .switch-toggle-slider {
  background: #8c57ff;
  color: #fff
}

.switch-input:active ~ .switch-toggle-slider {
  box-shadow: none
}

.switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 .25rem .05rem rgba(140, 87, 255, .1)
}

.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #8c57ff;
  color: #fff
}

.switch-primary.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none
}

.switch-primary.switch .switch-input:focus ~ .switch-toggle-slider {
  box-shadow: 0 0 .25rem .05rem rgba(140, 87, 255, .1)
}

.timeline .timeline-point-primary {
  background-color: #8c57ff !important;
  outline: 3px solid rgba(140, 87, 255, .12)
}

.timeline.timeline-outline .timeline-point-primary {
  border: 2px solid #8c57ff !important
}

.timeline .timeline-indicator-primary {
  background-color: #e8e2fb !important
}

.timeline .timeline-indicator-primary i {
  color: #8c57ff !important
}

.divider.divider-primary.divider-vertical:before, .divider.divider-primary.divider-vertical:after, .divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color: #8c57ff
}

.navbar.bg-primary {
  color: #e7dcff
}

.layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar, .layout-horizontal .navbar.bg-primary.layout-navbar {
  background-color: rgba(140, 87, 255, .85) !important
}

.layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar .search-input-wrapper .search-input, .layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar .search-input-wrapper .search-toggler, .layout-horizontal .navbar.bg-primary.layout-navbar .search-input-wrapper .search-input, .layout-horizontal .navbar.bg-primary.layout-navbar .search-input-wrapper .search-toggler {
  background-color: #8c57ff !important;
  border-radius: .625rem
}

[dir=rtl] .navbar.bg-primary.layout-navbar .navbar-search-wrapper .search-toggler > i:before {
  transform: scaleX(-1) !important
}

.navbar.bg-primary .navbar-brand, .navbar.bg-primary .navbar-brand a {
  color: #fff
}

.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus, .navbar.bg-primary .navbar-brand a:hover, .navbar.bg-primary .navbar-brand a:focus {
  color: #fff
}

.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon, .navbar.bg-primary .navbar-search-wrapper .search-input {
  color: #e7dcff
}

.navbar.bg-primary .search-input-wrapper .search-input, .navbar.bg-primary .search-input-wrapper .search-toggler {
  color: #e7dcff;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.navbar.bg-primary .navbar-nav > .nav-link, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e7dcff
}

.navbar.bg-primary .navbar-nav > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff
}

.navbar.bg-primary .navbar-nav > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #c3a7ff !important
}

.navbar.bg-primary .navbar-nav .show > .nav-link, .navbar.bg-primary .navbar-nav .active > .nav-link, .navbar.bg-primary .navbar-nav .nav-link.show, .navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff
}

.navbar.bg-primary .navbar-toggler {
  color: #e7dcff;
  border-color: rgba(255, 255, 255, .15)
}

.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.navbar.bg-primary .navbar-text {
  color: #e7dcff
}

.navbar.bg-primary .navbar-text a {
  color: #fff
}

.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff
}

.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, .15)
}

.menu.bg-primary {
  background-color: #8c57ff !important;
  color: #e7dcff
}

.menu.bg-primary .menu-link, .menu.bg-primary .menu-horizontal-prev, .menu.bg-primary .menu-horizontal-next {
  color: #e7dcff
}

.menu.bg-primary .menu-link:hover, .menu.bg-primary .menu-link:focus, .menu.bg-primary .menu-horizontal-prev:hover, .menu.bg-primary .menu-horizontal-prev:focus, .menu.bg-primary .menu-horizontal-next:hover, .menu.bg-primary .menu-horizontal-next:focus {
  color: #fff
}

.menu.bg-primary .menu-link.active, .menu.bg-primary .menu-horizontal-prev.active, .menu.bg-primary .menu-horizontal-next.active {
  color: #fff
}

.menu.bg-primary .menu-item.disabled .menu-link, .menu.bg-primary .menu-horizontal-prev.disabled, .menu.bg-primary .menu-horizontal-next.disabled {
  color: #c3a7ff !important
}

.menu.bg-primary .menu-item.active:not(.open) > .menu-link:not(.menu-toggle), .menu.bg-primary .menu-item.active:not(.open) > .menu-link:not(.menu-toggle)::before {
  color: #fff !important;
  border-color: #fff !important
}

.menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(270deg, #8f5bff 0%, #c5aaff 100%)
}

[dir=rtl] .menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(-270deg, #8f5bff 0%, #c5aaff 100%)
}

.menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle), .menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle)::before {
  background: #ede5ff;
  color: #8f5bff !important
}

.menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle {
  background: linear-gradient(270deg, #8f5bff 0%, #c5aaff 100%)
}

.menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle, .menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle:after {
  color: #fff
}

.menu.bg-primary .menu-inner-shadow {
  background: linear-gradient(#8c57ff 5%, rgba(140, 87, 255, 0.75) 45%, rgba(140, 87, 255, 0.2) 80%, transparent)
}

.menu.bg-primary .menu-text {
  color: #fff
}

.menu.bg-primary .menu-header {
  color: #d0bbff
}

.menu.bg-primary hr, .menu.bg-primary .menu-divider, .menu.bg-primary .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(255, 255, 255, .15) !important
}

.menu.bg-primary .menu-block::before {
  background-color: #d0bbff
}

.menu.bg-primary .ps__thumb-y, .menu.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.5913709804) !important
}

.footer.bg-primary {
  color: #e7dcff
}

.footer.bg-primary .footer-link {
  color: #e7dcff
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #e7dcff
}

.footer.bg-primary .footer-link.disabled {
  color: #c3a7ff !important
}

.footer.bg-primary .footer-text {
  color: #fff
}

.footer.bg-primary .show > .footer-link, .footer.bg-primary .active > .footer-link, .footer.bg-primary .footer-link.show, .footer.bg-primary .footer-link.active {
  color: #fff
}

.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, .15)
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:focus:not(:placeholder-shown) ~ label, .form-floating > .form-select:focus ~ label, .form-floating > .form-select:focus:not(:placeholder-shown) ~ label {
  color: #8c57ff
}

.form-floating-focused {
  background-color: #8c57ff
}

.form-floating-outline :not(select):focus + label, .form-floating-outline :not(select):focus + span:not(.flatpickr-day) {
  color: #8c57ff
}

.form-floating-outline label::after, .form-floating-outline > span::after {
  background: #fff
}

.form-floating-outline label.bg-body::after, .form-floating-outline > span.bg-body::after {
  background: #f4f5fa !important
}

.svg-illustration svg {
  fill: #8c57ff
}

.jstree .jstree-children .jstree-wholerow-hovered {
  background-color: rgba(46, 38, 61, .06)
}

.jstree .jstree-children .jstree-wholerow-clicked {
  background: rgba(140, 87, 255, .08)
}

.card.card-border-shadow-primary::after {
  border-bottom-color: #ba9aff
}

.card.card-border-shadow-primary:hover::after {
  border-bottom-color: #8c57ff
}

.card.card-hover-border-primary:hover, .card .card-hover-border-primary:hover {
  border-color: #d3bfff
}

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #8c57ff !important
}

a {
  color: #8c57ff
}

a:hover {
  color: #9868ff
}

.fill-primary {
  fill: #8c57ff
}

.noUi-target:not([disabled]) {
  background: rgba(140, 87, 255, .16)
}

.noUi-target:not([disabled]) .noUi-connect {
  background: #8c57ff
}

.noUi-target:not([disabled]) .noUi-handle {
  border-color: #8c57ff
}

.noUi-target:not([disabled]) .noUi-handle:hover {
  box-shadow: 0 0 0 8px rgba(140, 87, 255, .16)
}

.noUi-target:not([disabled]) .noUi-handle:active, .noUi-target:not([disabled]) .noUi-handle:focus {
  box-shadow: 0 0 0 13px rgba(140, 87, 255, .16)
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #8c57ff !important;
  color: #fff !important
}

.select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection {
  border-width: 2px;
  border-color: #8c57ff !important
}

.form-floating.select2-focus label {
  color: #8c57ff
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #8c57ff !important;
  color: #fff !important
}

.tagify--focus {
  border-color: #8c57ff !important
}

.tagify--focus ~ label {
  color: #8c57ff !important
}

.tagify__dropdown__item--active {
  background: #8c57ff !important
}

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: #8c57ff;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(46, 38, 61, .8)
}

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
  background: #8c57ff !important;
  color: #fff !important
}

.bootstrap-select .dropdown-toggle.show {
  border-width: 2px;
  border-color: #8c57ff
}

.form-floating:has(.bootstrap-select .dropdown-toggle.show) label {
  color: #8c57ff
}

.form-floating.form-floating-bootstrap-select label.form-floating-bootstrap-select-label {
  color: #8c57ff
}

.datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td .focused, .datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  background: #8c57ff !important;
  color: #fff !important
}

.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  color: #8c57ff !important;
  background: #f1ebff !important
}

.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
  background: #dcccff !important
}

.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
  background: rgba(241, 235, 255, .5) !important;
  color: rgba(140, 87, 255, .5) !important
}

.datepicker table tr td.today:not(.active), .datepicker table tr td.today:not(.active):hover {
  color: #8c57ff;
  background-color: #ede4ff
}

.flatpickr-day.today, .flatpickr-day.today:hover {
  color: #8c57ff !important;
  border-color: #ede4ff;
  background-color: #ede4ff !important
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
  color: #8c57ff !important;
  background: #f1ebff !important;
  border-color: #f1ebff !important
}

.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
  color: #fff !important;
  background: #8c57ff !important;
  border-color: #8c57ff !important
}

.daterangepicker td.active:not(.off) {
  background: #8c57ff !important;
  color: #fff
}

.daterangepicker .start-date:not(.end-date):not(.off), .daterangepicker .end-date:not(.start-date):not(.off) {
  background-color: #8c57ff;
  color: #fff;
  border: 0 !important
}

.daterangepicker .start-date:not(.end-date):not(.off):hover, .daterangepicker .end-date:not(.start-date):not(.off):hover {
  background-color: #8c57ff !important
}

.daterangepicker .input-mini.active {
  border-color: #8c57ff !important
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  color: #8c57ff !important;
  background-color: #f1ebff !important
}

.ranges li.active {
  color: #8c57ff !important;
  background-color: #ede4ff !important
}

li.ui-timepicker-selected, .ui-timepicker-list .ui-timepicker-selected:hover {
  color: #fff !important;
  background: #8c57ff !important
}

.ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar button:hover, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #8c57ff !important
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #8c57ff !important
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #8c57ff !important
}

.tt-suggestion:active, .tt-cursor {
  background: #8c57ff !important;
  color: #fff !important
}

.dropzone.dz-drag-hover {
  border-color: #8c57ff !important
}

.swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #8c57ff !important
}

.sk-primary.sk-plane, .sk-primary .sk-chase-dot:before, .sk-primary .sk-bounce-dot, .sk-primary .sk-wave-rect, .sk-primary.sk-pulse, .sk-primary .sk-swing-dot, .sk-primary .sk-circle-dot:before, .sk-primary .sk-circle-fade-dot:before, .sk-primary .sk-grid-cube, .sk-primary .sk-fold-cube:before {
  background-color: #8c57ff
}

.plyr input[type=range]::-ms-fill-lower {
  background: #8c57ff !important
}

.plyr input[type=range]:active::-webkit-slider-thumb {
  background: #8c57ff !important
}

.plyr input[type=range]:active::-moz-range-thumb {
  background: #8c57ff !important
}

.plyr input[type=range]:active::-ms-thumb {
  background: #8c57ff !important
}

.plyr--video .plyr__control.plyr__control--overlaid, .plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__control[aria-expanded=true], .plyr--video .plyr__controls button:hover {
  background: #8c57ff !important;
  color: #fff !important
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__control[aria-expanded=true], .plyr--audio .plyr__controls button:hover {
  background: #8c57ff !important;
  color: #fff !important
}

.plyr__play-large {
  background: #8c57ff !important;
  color: #fff !important
}

.plyr__progress--played, .plyr__volume--display {
  color: #8c57ff !important
}

.plyr--full-ui input[type=range] {
  color: #8c57ff !important
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #8c57ff !important
}

.fc .fc-event-primary:not(.fc-list-event) {
  background-color: #ede4ff !important;
  color: #8c57ff !important
}

.fc .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #8c57ff !important
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #8c57ff;
  color: #8c57ff
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):not(:last-child) {
  border-right: 1px solid #8c57ff !important
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: rgba(140, 87, 255, .05) !important;
  border-color: #8c57ff;
  color: #8c57ff
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):active, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active {
  background-color: rgba(140, 87, 255, .1) !important;
  border-color: #8c57ff !important;
  color: #8c57ff
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step-line, .swal2-progress-steps[class] .swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step {
  background: #8c57ff;
  color: #fff
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #eee6ff
}

.pcr-app .pcr-type.active, .pcr-app .pcr-save {
  background: #8c57ff !important
}

.icon-card.active {
  outline: 1px solid #8c57ff
}

.icon-card.active i, .icon-card.active svg {
  color: #8c57ff
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .line {
  border-color: #ede4ff
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .line:before {
  background-color: #ede4ff
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .step:not(.active) .bs-stepper-circle {
  border: 3px solid #ede4ff
}

.bs-stepper.wizard-icons .step.crossed .bs-stepper-label, .bs-stepper.wizard-icons .step.active .bs-stepper-label {
  color: #8c57ff
}

.bs-stepper .step.active .bs-stepper-circle {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid #8c57ff;
  color: #8c57ff
}

.bs-stepper .step.active .bs-stepper-icon i {
  color: #8c57ff !important
}

.bs-stepper .step.active .bs-stepper-icon svg {
  fill: #8c57ff !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-circle {
  background-color: #8c57ff !important;
  color: #fff !important;
  border-color: #8c57ff !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-circle i {
  visibility: visible
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon svg {
  fill: #8c57ff !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon i {
  color: #8c57ff !important
}

.bs-stepper .step.crossed + .line {
  border-color: #8c57ff
}

.bs-stepper .step.crossed + .line svg {
  fill: #8c57ff
}

.bs-stepper .step.crossed + .line i {
  color: #8c57ff
}

.bs-stepper .step.crossed + .line:before {
  background-color: #8c57ff
}

.app-chat .sidebar-body .chat-contact-list li.active {
  background-color: #8c57ff
}

.app-chat .app-chat-history .chat-history .chat-message.chat-message-right .chat-message-text {
  background-color: #8c57ff !important
}

.navbar.landing-navbar .navbar-nav .show > .nav-link, .navbar.landing-navbar .navbar-nav .active > .nav-link, .navbar.landing-navbar .navbar-nav .nav-link.show, .navbar.landing-navbar .navbar-nav .nav-link.active, .navbar.landing-navbar .navbar-nav .nav-link:hover {
  color: #8c57ff !important
}

.landing-features .features-icon-wrapper .features-icon-box .features-icon {
  border: 2px solid #dac9ff
}

.landing-features .features-icon-wrapper .features-icon-box:hover .features-icon {
  background-color: #ede4ff
}

.bg-navbar-theme {
  color: #433c50
}

.layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar, .layout-horizontal .bg-navbar-theme.layout-navbar {
  background-color: rgba(255, 255, 255, .85) !important
}

.layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar .search-input-wrapper .search-input, .layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar .search-input-wrapper .search-toggler, .layout-horizontal .bg-navbar-theme.layout-navbar .search-input-wrapper .search-input, .layout-horizontal .bg-navbar-theme.layout-navbar .search-input-wrapper .search-toggler {
  background-color: #fff !important;
  border-radius: .625rem
}

[dir=rtl] .bg-navbar-theme.layout-navbar .navbar-search-wrapper .search-toggler > i:before {
  transform: scaleX(-1) !important
}

.bg-navbar-theme .navbar-brand, .bg-navbar-theme .navbar-brand a {
  color: #433c50
}

.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus, .bg-navbar-theme .navbar-brand a:hover, .bg-navbar-theme .navbar-brand a:focus {
  color: #433c50
}

.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon, .bg-navbar-theme .navbar-search-wrapper .search-input {
  color: #433c50
}

.bg-navbar-theme .search-input-wrapper .search-input, .bg-navbar-theme .search-input-wrapper .search-toggler {
  color: #433c50;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.bg-navbar-theme .navbar-nav > .nav-link, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link {
  color: #433c50
}

.bg-navbar-theme .navbar-nav > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #433c50
}

.bg-navbar-theme .navbar-nav > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #8e8a96 !important
}

.bg-navbar-theme .navbar-nav .show > .nav-link, .bg-navbar-theme .navbar-nav .active > .nav-link, .bg-navbar-theme .navbar-nav .nav-link.show, .bg-navbar-theme .navbar-nav .nav-link.active {
  color: #433c50
}

.bg-navbar-theme .navbar-toggler {
  color: #433c50;
  border-color: rgba(67, 60, 80, .075)
}

.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(109, 103, 119, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.bg-navbar-theme .navbar-text {
  color: #433c50
}

.bg-navbar-theme .navbar-text a {
  color: #433c50
}

.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #433c50
}

.bg-navbar-theme hr {
  border-color: rgba(67, 60, 80, .075)
}

.layout-horizontal .layout-navbar {
  box-shadow: 0 1px 0 #e6e5e8
}

.layout-navbar-fixed .layout-page:not(.window-scrolled) .layout-navbar.navbar-detached {
  background: #f4f5fa
}

.bg-menu-theme {
  background-color: #282a42 !important;
  color: #d1d1e7
}

.bg-menu-theme .menu-link, .bg-menu-theme .menu-horizontal-prev, .bg-menu-theme .menu-horizontal-next {
  color: #d1d1e7
}

.bg-menu-theme .menu-link:hover, .bg-menu-theme .menu-link:focus, .bg-menu-theme .menu-horizontal-prev:hover, .bg-menu-theme .menu-horizontal-prev:focus, .bg-menu-theme .menu-horizontal-next:hover, .bg-menu-theme .menu-horizontal-next:focus {
  color: #ededff
}

.bg-menu-theme .menu-link.active, .bg-menu-theme .menu-horizontal-prev.active, .bg-menu-theme .menu-horizontal-next.active {
  color: #ededff
}

.bg-menu-theme .menu-item.disabled .menu-link, .bg-menu-theme .menu-horizontal-prev.disabled, .bg-menu-theme .menu-horizontal-next.disabled {
  color: #8d8ea5 !important
}

.bg-menu-theme .menu-item.active:not(.open) > .menu-link:not(.menu-toggle), .bg-menu-theme .menu-item.active:not(.open) > .menu-link:not(.menu-toggle)::before {
  color: #fff !important;
  border-color: #fff !important
}

.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(270deg, #8c57ff 0%, #c3a8ff 100%)
}

[dir=rtl] .bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(-270deg, #8c57ff 0%, #c3a8ff 100%)
}

.bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle), .bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle)::before {
  background: #3f396a;
  color: #8c57ff !important
}

.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle {
  background: linear-gradient(270deg, #8c57ff 0%, #c3a8ff 100%)
}

.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle, .bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle:after {
  color: #fff
}

.bg-menu-theme .menu-inner-shadow {
  background: linear-gradient(#282a42 5%, rgba(40, 42, 66, 0.75) 45%, rgba(40, 42, 66, 0.2) 80%, transparent)
}

.bg-menu-theme .menu-text {
  color: #ededff
}

.bg-menu-theme .menu-header {
  color: #a7a7be
}

.bg-menu-theme hr, .bg-menu-theme .menu-divider, .bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(237, 237, 255, .06) !important
}

.bg-menu-theme .menu-block::before {
  background-color: #a7a7be
}

.bg-menu-theme .ps__thumb-y, .bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(237, 237, 255, .7307639216) !important
}

.app-brand .layout-menu-toggle svg {
  color: rgba(234, 234, 255, .8705882353)
}

.menu-inner > .menu-header::before {
  background-color: #464963 !important
}

.bg-menu-theme.menu-vertical .menu-item:not(.active) .menu-link:hover {
  background-color: rgba(244, 245, 250, .05);
  color: rgba(234, 234, 255, .8705882353)
}

.bg-menu-theme.menu-vertical .menu-item.open > .menu-link {
  background-color: rgba(244, 245, 250, .08);
  color: rgba(234, 234, 255, .8705882353)
}

.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub {
  background: #282a42
}

.bg-menu-theme.menu-horizontal .menu-inner > .menu-item .menu-sub .menu-item.active > .menu-link.menu-toggle {
  background-color: rgba(244, 245, 250, .08)
}

.bg-menu-theme.menu-horizontal .menu-item .menu-link:hover {
  background-color: rgba(244, 245, 250, .05);
  color: rgba(234, 234, 255, .8705882353)
}

.bg-menu-theme .menu-inner > .menu-item .menu-link.menu-toggle:after {
  color: #d5d1ea
}

.bg-footer-theme {
  color: #8c57ff
}

.bg-footer-theme .footer-link {
  color: #8c57ff
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #8c57ff
}

.bg-footer-theme .footer-link.disabled {
  color: #b696fd !important
}

.bg-footer-theme .footer-text {
  color: #433c50
}

.bg-footer-theme .show > .footer-link, .bg-footer-theme .active > .footer-link, .bg-footer-theme .footer-link.show, .bg-footer-theme .footer-link.active {
  color: #433c50
}

.bg-footer-theme hr {
  border-color: rgba(67, 60, 80, .0769076471)
}
