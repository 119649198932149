/*!
 * bsStepper v1.7.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - 2019 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
.bs-stepper .step-trigger {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: .25rem;
  transition: background-color .15s ease-out, color .15s ease-out
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer
}

.bs-stepper .step-trigger:disabled, .bs-stepper .step-trigger.disabled {
  pointer-events: none;
  opacity: .65
}

.bs-stepper .step-trigger:focus {
  color: #007bff;
  outline: none
}

.bs-stepper .step-trigger:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.06)
}

@media(max-width: 520px) {
  .bs-stepper .step-trigger {
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px
  }
}

.bs-stepper-label {
  display: inline-block;
  margin: .25rem
}

.bs-stepper-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center
}

@media(max-width: 520px) {
  .bs-stepper-header {
    margin: 0 -10px;
    text-align: center
  }
}

.bs-stepper-line, .bs-stepper .line {
  -ms-flex: 1 0 32px;
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.12)
}

@media(max-width: 400px) {
  .bs-stepper-line, .bs-stepper .line {
    -ms-flex-preferred-size: 20px;
    flex-basis: 20px
  }
}

.bs-stepper-circle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: .5em 0;
  margin: .25rem;
  line-height: 1em;
  color: #fff;
  background-color: #6c757d;
  border-radius: 1em
}

.active .bs-stepper-circle {
  background-color: #007bff
}

.bs-stepper-content {
  padding: 0 20px 20px
}

@media(max-width: 520px) {
  .bs-stepper-content {
    padding: 0
  }
}

.bs-stepper.vertical {
  display: -ms-flexbox;
  display: flex
}

.bs-stepper.vertical .bs-stepper-header {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0
}

.bs-stepper.vertical .bs-stepper-pane, .bs-stepper.vertical .content {
  display: block
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade), .bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden
}

.bs-stepper-pane:not(.fade), .bs-stepper .content:not(.fade) {
  display: none
}

.bs-stepper .content.fade, .bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: .3s;
  transition-property: opacity
}

.bs-stepper-pane.fade.active, .bs-stepper .content.fade.active {
  visibility: visible;
  opacity: 1
}

.bs-stepper-pane.active:not(.fade), .bs-stepper .content.active:not(.fade) {
  display: block;
  visibility: visible
}

.bs-stepper-pane.dstepper-block, .bs-stepper .content.dstepper-block {
  display: block
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none, .bs-stepper:not(.vertical) .content.dstepper-none {
  display: none
}

.vertical .bs-stepper-pane.fade.dstepper-none, .vertical .content.fade.dstepper-none {
  visibility: hidden
}

.bs-stepper {
  border-radius: .375rem
}

.bs-stepper .line {
  flex: 0;
  min-width: auto;
  min-height: auto;
  background-color: rgba(0, 0, 0, 0);
  margin: 0
}

.bs-stepper .line i::before {
  font-size: 1.5rem
}

.bs-stepper .bs-stepper-header {
  padding: 1.8rem 1.5rem
}

.bs-stepper .bs-stepper-header .step .step-trigger {
  padding: 0 1rem;
  flex-wrap: nowrap;
  font-weight: 500
}

.bs-stepper .bs-stepper-header .step .step-trigger:focus {
  color: inherit
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  display: inline-flex;
  align-items: center
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-number {
  font-weight: 500;
  font-size: 2.125rem;
  margin-right: .25rem;
  line-height: 1
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-title {
  line-height: 1;
  font-weight: 500;
  font-size: .9375rem
}

.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-subtitle {
  font-size: .8125rem;
  line-height: .75rem;
  font-weight: 400
}

html:not([dir=rtl]) .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  margin-left: .35rem
}

[dir=rtl] .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  margin-right: .35rem
}

.bs-stepper .bs-stepper-header .step .step-trigger:hover {
  background-color: rgba(0, 0, 0, 0)
}

html:not([dir=rtl]) .bs-stepper .bs-stepper-header .step:first-child .step-trigger {
  padding-left: 0
}

[dir=rtl] .bs-stepper .bs-stepper-header .step:first-child .step-trigger {
  padding-right: 0
}

html:not([dir=rtl]) .bs-stepper .bs-stepper-header .step:last-child .step-trigger {
  padding-right: 0
}

[dir=rtl] .bs-stepper .bs-stepper-header .step:last-child .step-trigger {
  padding-left: 0
}

.bs-stepper .bs-stepper-header .step .bs-stepper-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  padding: unset
}

.bs-stepper .bs-stepper-header .step .bs-stepper-circle i {
  visibility: hidden
}

.bs-stepper .bs-stepper-header .step .bs-stepper-circle i::before {
  font-size: .875rem
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .line {
  border-width: 1.75px;
  border-style: solid;
  border-radius: 3px;
  width: 100%;
  flex-basis: auto
}

.bs-stepper .bs-stepper-content {
  padding: 1.25rem 1.25rem;
  border-radius: .375rem
}

.bs-stepper.vertical .bs-stepper-header {
  min-width: 18rem
}

.bs-stepper.vertical .bs-stepper-header .step .step-trigger {
  padding: .5rem 0
}

.bs-stepper.vertical .bs-stepper-header .step:first-child .step-trigger {
  padding-top: 0
}

.bs-stepper.vertical .bs-stepper-header .step:last-child .step-trigger {
  padding-bottom: 0
}

.bs-stepper.vertical .bs-stepper-header .line {
  position: relative;
  min-height: 1px;
  border: none
}

.bs-stepper.vertical .bs-stepper-header .line:before {
  position: absolute;
  top: -0.75rem;
  left: .8rem;
  display: block;
  height: 1.55rem;
  width: 3px;
  border-radius: 3px;
  content: ""
}

.bs-stepper.vertical .bs-stepper-content {
  width: 100%
}

.bs-stepper.vertical .bs-stepper-content .content:not(.active) {
  display: none
}

.bs-stepper.vertical.wizard-icons .step {
  text-align: center;
  padding: .75rem 0
}

.bs-stepper.vertical.wizard-icons .bs-stepper-header .line:before {
  top: -0.7rem;
  left: 50%;
  margin-left: -0.06rem
}

.bs-stepper.wizard-icons .bs-stepper-header {
  justify-content: space-around
}

.bs-stepper.wizard-icons .bs-stepper-header .step-trigger {
  flex-direction: column
}

.bs-stepper.wizard-icons .bs-stepper-header .step-trigger .bs-stepper-icon svg {
  height: 3.125rem;
  width: 3.125rem;
  margin-bottom: .5rem
}

.bs-stepper.wizard-icons .bs-stepper-header .step-trigger .bs-stepper-icon i {
  font-size: 1.6rem
}

.bs-stepper.wizard-modern .bs-stepper-header {
  border-bottom: none !important
}

.bs-stepper.wizard-modern.vertical .bs-stepper-header {
  border-right: none !important
}

.light-style .bs-stepper {
  background-color: #fff
}

.light-style .bs-stepper .line i {
  color: #b4b2b7
}

.light-style .bs-stepper .bs-stepper-header {
  border-bottom: 1px solid #e7e7e8
}

.light-style .bs-stepper .bs-stepper-header .bs-stepper-title {
  color: #544f5a
}

.light-style .bs-stepper .bs-stepper-header .bs-stepper-label .bs-stepper-title {
  color: #544f5a
}

.light-style .bs-stepper .bs-stepper-header .bs-stepper-label .bs-stepper-subtitle {
  color: #89868d
}

.light-style .bs-stepper .bs-stepper-header .step:not(.active) .bs-stepper-circle {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(58, 53, 65, .38)
}

.light-style .bs-stepper .bs-stepper-header .step.crossed .bs-stepper-label .bs-stepper-number, .light-style .bs-stepper .bs-stepper-header .step.active .bs-stepper-label .bs-stepper-number {
  color: #544f5a !important
}

.light-style .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-number {
  color: #b4b2b7
}

.light-style .bs-stepper .bs-stepper-header .step .step-trigger:disabled .bs-stepper-label .bs-stepper-number {
  color: #b4b2b7
}

.light-style .bs-stepper.vertical .bs-stepper-header {
  border-bottom: none
}

@media(max-width: 991.98px) {
  .light-style .bs-stepper.vertical .bs-stepper-header {
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #e7e7e8
  }
}

.light-style .bs-stepper.wizard-modern {
  background-color: rgba(0, 0, 0, 0)
}

.light-style .bs-stepper.wizard-modern .bs-stepper-content {
  background-color: #fff;
  box-shadow: 0 .375rem 1rem 0 rgba(58, 53, 65, .12)
}

.light-style .bs-stepper:not(.wizard-modern) {
  box-shadow: 0 .375rem 1rem 0 rgba(58, 53, 65, .12)
}

.light-style .bs-stepper.wizard-icons .bs-stepper-header .bs-stepper-icon svg {
  fill: #544f5a
}

[dir=rtl].light-style .bs-stepper.vertical .bs-stepper-header {
  border-left: 1px solid #e7e7e8
}

html:not([dir=rtl]).light-style .bs-stepper.vertical .bs-stepper-header {
  border-right: 1px solid #e7e7e8
}

.dark-style .bs-stepper {
  background-color: #312d4b
}

.dark-style .bs-stepper .line i {
  color: #76728e
}

.dark-style .bs-stepper .bs-stepper-header {
  border-bottom: 1px solid #474360
}

.dark-style .bs-stepper .bs-stepper-header .bs-stepper-title {
  color: #cfcbe5
}

.dark-style .bs-stepper .bs-stepper-header .bs-stepper-label .bs-stepper-title {
  color: #cfcbe5
}

.dark-style .bs-stepper .bs-stepper-header .bs-stepper-label .bs-stepper-subtitle {
  color: #9e9ab5
}

.dark-style .bs-stepper .bs-stepper-header .line {
  color: #9e9ab5
}

.dark-style .bs-stepper .bs-stepper-header .step:not(.active) .bs-stepper-circle {
  background-color: rgba(0, 0, 0, 0);
  color: #9e9ab5
}

.dark-style .bs-stepper .bs-stepper-header .step.crossed .bs-stepper-label .bs-stepper-number, .dark-style .bs-stepper .bs-stepper-header .step.active .bs-stepper-label .bs-stepper-number {
  color: #cfcbe5 !important
}

.dark-style .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label .bs-stepper-number {
  color: #76728e
}

.dark-style .bs-stepper .bs-stepper-header .step .step-trigger:disabled .bs-stepper-label .bs-stepper-number {
  color: #76728e
}

.dark-style .bs-stepper.vertical .bs-stepper-header {
  border-bottom: none
}

@media(max-width: 991.98px) {
  .dark-style .bs-stepper.vertical .bs-stepper-header {
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #474360
  }
}

.dark-style .bs-stepper.wizard-modern {
  background-color: rgba(0, 0, 0, 0)
}

.dark-style .bs-stepper.wizard-modern .bs-stepper-content {
  background-color: #312d4b;
  box-shadow: 0 .375rem 1rem 0 rgba(19, 17, 32, .18)
}

.dark-style .bs-stepper:not(.wizard-modern) {
  box-shadow: 0 .375rem 1rem 0 rgba(19, 17, 32, .18)
}

.dark-style .bs-stepper.wizard-icons .bs-stepper-header .bs-stepper-icon i {
  color: #9e9ab5
}

.dark-style .bs-stepper.wizard-icons .bs-stepper-header .bs-stepper-icon svg {
  fill: #cfcbe5
}

.dark-style .bs-stepper.wizard-icons .bs-stepper-header .bs-stepper-label {
  color: #cfcbe5
}

[dir=rtl].dark-style .bs-stepper.vertical .bs-stepper-header {
  border-left: 1px solid #474360
}

html:not([dir=rtl]).dark-style .bs-stepper.vertical .bs-stepper-header {
  border-right: 1px solid #474360
}

[dir=rtl] .bs-stepper .bs-stepper-content .btn-next:not(.btn-submit) i:before, [dir=rtl] .bs-stepper .bs-stepper-content .btn-prev:not(.btn-submit) i:before {
  transform: scaleX(-1) !important
}

[dir=rtl] .bs-stepper.vertical .bs-stepper-header .line:before {
  left: auto;
  right: .8rem
}

[dir=rtl] .bs-stepper.vertical.wizard-icons .bs-stepper-header .line:before {
  right: 50%
}

[dir=rtl] .bs-stepper.wizard-modern.vertical .bs-stepper-header {
  border-left: none !important
}

@media(min-width: 992px) {
  [dir=rtl] .bs-stepper .bs-stepper-header .line i:before {
    transform: scaleX(-1) !important
  }
}

@media(max-width: 991.98px) {
  [dir=rtl] .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
    margin-left: 0;
    margin-right: .35rem
  }
  [dir=rtl] .bs-stepper .bs-stepper-header .line:before {
    left: 0;
    right: .8rem
  }
  [dir=rtl] .bs-stepper.wizard-icons .bs-stepper-header .line:before {
    margin-right: .75rem
  }
}

@media(max-width: 991.98px) {
  .bs-stepper .bs-stepper-header {
    flex-direction: column;
    align-items: flex-start
  }
  .bs-stepper .bs-stepper-header .step .step-trigger {
    padding: .5rem 0;
    flex-direction: row
  }
  .bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
    margin-left: .35rem
  }
  .bs-stepper .bs-stepper-header .step:first-child .step-trigger {
    padding-top: 0
  }
  .bs-stepper .bs-stepper-header .step:last-child .step-trigger {
    padding-bottom: 0
  }
  .bs-stepper.vertical {
    flex-direction: column
  }
  .bs-stepper.vertical .bs-stepper-header {
    align-items: flex-start
  }
  .bs-stepper.vertical.wizard-icons .bs-stepper-header .line:before {
    left: .75rem;
    margin-left: 0
  }
  .bs-stepper:not(.vertical) .bs-stepper-header .line i {
    display: none
  }
  .bs-stepper:not(.vertical):not(.wizard-icons) .bs-stepper-header .line {
    position: relative;
    min-height: 1px;
    border: none
  }
  .bs-stepper:not(.vertical):not(.wizard-icons) .bs-stepper-header .line:before {
    position: absolute;
    top: -0.75rem;
    left: .8rem;
    display: block;
    height: 1.55rem;
    width: 3px;
    border-radius: 3px;
    content: ""
  }
  .bs-stepper.wizard-icons .bs-stepper-header .bs-stepper-icon svg {
    margin-top: .5rem
  }
}

@media(max-width: 520px) {
  .bs-stepper-header {
    margin: 0
  }
}

.wizard-vertical-icons.vertical .bs-stepper-header {
  min-width: 15rem
}

.wizard-vertical-icons.vertical .bs-stepper-header .step .step-trigger {
  padding-top: .7125rem;
  padding-bottom: .7125rem
}

.light-style .wizard-vertical-icons.vertical .bs-stepper-header .step .avatar-initial {
  background-color: #f1f1f2;
  color: #8a8d93
}

.light-style .wizard-vertical-icons.vertical .bs-stepper-header .step.active .avatar-initial {
  background-color: #9055fd;
  color: #fff
}

.dark-style .wizard-vertical-icons.vertical .bs-stepper-header .step .avatar-initial {
  background-color: #3c3954;
  color: #8a8d93
}

.dark-style .wizard-vertical-icons.vertical .bs-stepper-header .step.active .avatar-initial {
  background-color: #9055fd;
  color: #fff
}
