body {
  background: #f4f5fa
}

.bg-body {
  background: #f4f5fa !important
}

.text-primary {
  color: #9055fd !important
}

.text-body[href]:hover {
  color: #824de4 !important
}

.bg-primary {
  background-color: #9055fd !important
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #8951f0 !important
}

.dropdown-notifications-item:not(.mark-as-read) .dropdown-notifications-read span {
  background-color: #9055fd
}

.bg-label-primary {
  background-color: #eee6ff !important;
  color: #9055fd !important
}

.bg-label-hover-primary {
  background-color: #eee6ff !important;
  color: #9055fd !important
}

.bg-label-hover-primary:hover {
  background-color: #9055fd !important;
  color: #fff !important
}

.bg-gradient-primary {
  background-image: linear-gradient(45deg, #9055fd, #c8aafe) !important
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus, .pagination li.active > a:not(.page-link), .pagination li.active > a:not(.page-link):hover, .pagination li.active > a:not(.page-link):focus {
  border-color: #9055fd;
  background-color: #9055fd;
  color: #fff
}

.page-item.active .page-link.waves-effect .waves-ripple, .pagination li.active > a:not(.page-link).waves-effect .waves-ripple {
  background: radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.pagination-outline-primary .page-item.active .page-link, .pagination-outline-primary .page-item.active .page-link:hover, .pagination-outline-primary .page-item.active .page-link:focus, .pagination-outline-primary.pagination li.active > a:not(.page-link), .pagination-outline-primary.pagination li.active > a:not(.page-link):hover, .pagination-outline-primary.pagination li.active > a:not(.page-link):focus {
  border-color: #c8aafe;
  color: #9055fd;
  background-color: #f6f1ff
}

.pagination-outline-primary .page-item.active .page-link.waves-effect .waves-ripple, .pagination-outline-primary.pagination li.active > a:not(.page-link).waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(58, 53, 65, 0) 70%)
}

.modal-onboarding .carousel-indicators [data-bs-target] {
  background-color: #9055fd
}

.carousel-control-prev, .carousel-control-next {
  color: #9055fd
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #9055fd
}

.list-group-item-primary {
  border-color: #9b66fd;
  background-color: #f2ebff;
  color: #824de4 !important
}

a.list-group-item-primary, button.list-group-item-primary {
  color: #824de4
}

a.list-group-item-primary:hover, a.list-group-item-primary:focus, button.list-group-item-primary:hover, button.list-group-item-primary:focus {
  border-color: #9b66fd;
  background-color: #e6dff2;
  color: #824de4
}

a.list-group-item-primary.active, button.list-group-item-primary.active {
  border-color: #9055fd !important;
  background-color: #9055fd !important;
  color: #fff !important
}

.list-group-item.active {
  background-color: #f6f1ff;
  color: #544f5a
}

.list-group-item.active.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(58, 53, 65, 0) 70%)
}

.list-group.list-group-timeline .list-group-timeline-primary:before {
  border-color: #9055fd;
  background-color: #9055fd
}

.alert-primary {
  background-color: #f2ebff;
  border-color: #f2ebff;
  color: #824de4
}

.alert-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23824de4'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-primary .alert-link {
  color: #824de4
}

.alert-primary hr {
  background-color: #824de4 !important
}

.alert-outline-primary {
  border-color: #9055fd;
  color: #824de4
}

.alert-outline-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239055fd'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-outline-primary .alert-link {
  color: #824de4
}

.alert-outline-primary hr {
  background-color: #9055fd !important
}

.alert-solid-primary {
  background-color: #9055fd;
  color: #fff
}

.alert-solid-primary .btn-close {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
}

.alert-solid-primary .alert-link {
  color: #fff
}

.alert-solid-primary hr {
  background-color: #fff !important
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner {
  background: #9055fd;
  color: #fff
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=top] .tooltip-arrow::before {
  border-top-color: #9055fd
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
  border-left-color: #9055fd
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=left] .tooltip-arrow::before {
  border-right-color: #9055fd
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=bottom] .tooltip-arrow::before {
  border-bottom-color: #9055fd
}

.tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
  border-right-color: #9055fd
}

[dir=rtl] .tooltip-primary.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before, [dir=rtl] .tooltip-primary > .tooltip.bs-tooltip-auto[data-popper-placement=right] .tooltip-arrow::before {
  border-left-color: #9055fd
}

.popover-primary, .popover-primary > .popover {
  border-color: rgba(0, 0, 0, 0);
  background: #9055fd
}

.popover-primary .popover-header, .popover-primary > .popover .popover-header {
  border-color: rgba(255, 255, 255, .2);
  background: rgba(0, 0, 0, 0);
  color: #fff
}

.popover-primary .popover-body, .popover-primary > .popover .popover-body {
  background: rgba(0, 0, 0, 0);
  color: rgba(255, 255, 255, .8)
}

.popover-primary > .popover-arrow::before, .popover-primary > .popover > .popover-arrow::before {
  border-color: rgba(0, 0, 0, 0)
}

.popover-primary.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=top] > .popover-arrow::after {
  border-top-color: #9055fd !important
}

.popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
  border-right-color: #9055fd !important
}

[dir=rtl] .popover-primary.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after, [dir=rtl] .popover-primary > .popover.bs-popover-auto[data-popper-placement=right] > .popover-arrow::after {
  border-left-color: #9055fd !important
}

.popover-primary.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] > .popover-arrow::after {
  border-bottom-color: #9055fd !important
}

.popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
  border-left-color: #9055fd !important
}

[dir=rtl] .popover-primary.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after, [dir=rtl] .popover-primary > .popover.bs-popover-auto[data-popper-placement=left] > .popover-arrow::after {
  border-right-color: #9055fd !important
}

.popover-primary.bs-popover-auto[data-popper-placement=bottom] .popover-header::before, .popover-primary > .popover.bs-popover-auto[data-popper-placement=bottom] .popover-header::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0) !important
}

.table-primary {
  --bs-table-bg: #e9ddff;
  --bs-table-striped-bg: #e0d5f6;
  --bs-table-striped-color: #3a3541;
  --bs-table-active-bg: #dbd0f0;
  --bs-table-active-color: #3a3541;
  --bs-table-hover-bg: #e2d6f7;
  --bs-table-hover-color: #3a3541;
  color: #3a3541;
  border-color: #d4c9e8
}

.table-primary .btn-icon {
  color: #3a3541
}

.btn-primary {
  color: #fff;
  background-color: #9055fd;
  border-color: #9055fd
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #804be0 !important;
  border-color: #804be0 !important
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #804be0;
  border-color: #804be0;
  box-shadow: none
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .btn-primary.show.dropdown-toggle, .show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #7344ca !important;
  border-color: #7344ca !important
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .btn-primary.show.dropdown-toggle:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none
}

.btn-group .btn-primary, .input-group .btn-primary {
  border-right: 1px solid #7344ca;
  border-left: 1px solid #7344ca
}

.btn-group-vertical .btn-primary {
  border-top: 1px solid #7344ca;
  border-bottom: 1px solid #7344ca
}

.btn-label-primary {
  color: #9055fd;
  border-color: #ede4ff;
  background: #ede4ff
}

.btn-label-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-label-primary:hover {
  border-color: #e4d6ff !important;
  background: #e4d6ff !important;
  color: #9055fd !important
}

.btn-label-primary:focus, .btn-label-primary.focus {
  color: #9055fd;
  background: #e0cffe;
  border-color: #e0cffe
}

.btn-label-primary:active, .btn-label-primary.active, .btn-label-primary.show.dropdown-toggle, .show > .btn-label-primary.dropdown-toggle {
  color: #9055fd !important;
  background-color: #deccfe !important;
  border-color: #deccfe !important
}

.btn-group .btn-label-primary, .input-group .btn-label-primary {
  border-right: 1px solid #deccfe;
  border-left: 1px solid #deccfe
}

.btn-group-vertical .btn-label-primary {
  border-top: 1px solid #deccfe;
  border-bottom: 1px solid #deccfe
}

.btn-text-primary {
  color: #9055fd
}

.btn-text-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-text-primary:hover {
  border-color: rgba(0, 0, 0, 0);
  background: #f7f3ff;
  color: #9055fd
}

.btn-text-primary:focus, .btn-text-primary.focus {
  color: #9055fd;
  background: #f4eeff
}

.btn-text-primary:active, .btn-text-primary.active, .btn-text-primary.show.dropdown-toggle, .show > .btn-text-primary.dropdown-toggle {
  color: #9055fd !important;
  background: #eee6ff !important;
  border-color: rgba(0, 0, 0, 0) !important
}

.btn-group .btn-text-primary, .input-group .btn-text-primary {
  border-right: 1px solid #eee6ff;
  border-left: 1px solid #eee6ff
}

.btn-group-vertical .btn-text-primary {
  border-top: 1px solid #eee6ff;
  border-bottom: 1px solid #eee6ff
}

.btn-outline-primary {
  color: #9055fd;
  border-color: #c8aafe;
  background: rgba(0, 0, 0, 0)
}

.btn-outline-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.btn-outline-primary:hover {
  color: #9055fd !important;
  background-color: #f7f3ff !important;
  border-color: #c8aafe !important
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  color: #9055fd;
  background-color: #e4d6ff;
  border-color: #c8aafe
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #9055fd !important;
  background-color: #e0cffe !important;
  border-color: #c8aafe !important
}

.btn-outline-primary .badge {
  background: #9055fd;
  border-color: #9055fd;
  color: #fff
}

.btn-outline-primary:hover .badge, .btn-outline-primary:focus:hover .badge, .btn-outline-primary:active .badge, .btn-outline-primary.active .badge, .show > .btn-outline-primary.dropdown-toggle .badge {
  background: #9055fd;
  border-color: #9055fd;
  color: #fff
}

.dropdown-item.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active {
  background-color: rgba(144, 85, 253, .1);
  color: #9055fd !important
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: rgba(144, 85, 253, .1);
  color: #9055fd !important
}

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active.btn, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item).btn {
  color: #fff !important
}

.nav .nav-link:hover, .nav .nav-link:focus {
  color: #824de4
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #9055fd;
  color: #fff
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
  color: #9055fd
}

.nav-tabs .nav-link.waves-effect .waves-ripple {
  background: radial-gradient(rgba(144, 85, 253, 0.2) 0, rgba(144, 85, 253, 0.3) 40%, rgba(144, 85, 253, 0.4) 50%, rgba(144, 85, 253, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.nav-tabs .tab-slider {
  background-color: #9055fd
}

.form-control:focus, .form-select:focus {
  border-color: #9055fd !important
}

.form-floating-outline .form-control:focus, .form-floating-outline .form-select:focus {
  border-color: #9055fd !important
}

.input-group:not(.input-group-floating):focus-within .form-control, .input-group:not(.input-group-floating):focus-within .input-group-text {
  border-color: #9055fd
}

.form-check-input:focus {
  border-color: #9055fd
}

.form-check-input:active {
  border-color: #9055fd
}

.form-check-input:hover::after {
  background: rgba(58, 53, 65, .04)
}

.form-check-input:checked {
  background-color: #9055fd;
  border-color: #9055fd
}

.form-check-input:checked::after {
  background: rgba(144, 85, 253, .08) !important
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #9055fd;
  border-color: #9055fd
}

.custom-option.checked {
  border: 2px solid #9055fd;
  margin: 0 !important
}

.custom-option.custom-option-label.checked {
  background-color: rgba(144, 85, 253, .12);
  color: #9055fd
}

.custom-option.custom-option-label.checked .custom-option-header span, .custom-option.custom-option-label.checked .custom-option-title {
  color: #9055fd
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239055fd'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-control:focus ~ .form-label {
  border-color: #9055fd
}

.form-control:focus ~ .form-label::after {
  border-color: inherit
}

.form-range::-webkit-slider-thumb {
  background-color: #9055fd
}

.form-range::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(144, 85, 253, .15), 0px 3px 14px 0px rgba(58, 53, 65, .14)
}

.form-range::-webkit-slider-thumb:active {
  background-color: #9055fd;
  box-shadow: 0 0 0 10px rgba(144, 85, 253, .2), 0px 3px 14px 0px rgba(58, 53, 65, .14)
}

.form-range::-moz-range-thumb:hover {
  box-shadow: 0 0 0 8px rgba(144, 85, 253, .15), 0px 3px 14px 0px rgba(58, 53, 65, .14)
}

.form-range::-moz-range-thumb:active {
  box-shadow: 0 0 0 10px rgba(144, 85, 253, .2), 0px 3px 14px 0px rgba(58, 53, 65, .14)
}

.form-range::-webkit-slider-runnable-track {
  background-color: #9055fd
}

.form-range::-moz-range-track {
  background-color: #9055fd
}

.switch-input:checked ~ .switch-toggle-slider {
  background: rgba(144, 85, 253, .38)
}

.switch-input:checked ~ .switch-toggle-slider::after {
  background: #9055fd
}

.switch-input:checked:hover ~ .switch-toggle-slider::before {
  background: rgba(144, 85, 253, .08)
}

.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider {
  background: rgba(144, 85, 253, .38)
}

.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider::after {
  background: #9055fd
}

.switch-primary.switch .switch-input:checked:hover ~ .switch-toggle-slider::before {
  background: rgba(144, 85, 253, .08)
}

.timeline .timeline-point-primary {
  background-color: #9055fd !important;
  outline: 3px solid rgba(144, 85, 253, .12)
}

.timeline.timeline-outline .timeline-point-primary {
  border: 2px solid #9055fd !important
}

.timeline .timeline-indicator-primary {
  background-color: #e8e2fa !important
}

.timeline .timeline-indicator-primary i {
  color: #9055fd !important
}

.divider.divider-primary.divider-vertical:before, .divider.divider-primary.divider-vertical:after, .divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color: #9055fd
}

.navbar.bg-primary {
  color: #e8dbff
}

.layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar, .layout-horizontal .navbar.bg-primary.layout-navbar {
  background-color: rgba(144, 85, 253, .9) !important
}

.layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar .search-input-wrapper .search-input, .layout-navbar-fixed .window-scrolled .navbar.bg-primary.layout-navbar .search-input-wrapper .search-toggler, .layout-horizontal .navbar.bg-primary.layout-navbar .search-input-wrapper .search-input, .layout-horizontal .navbar.bg-primary.layout-navbar .search-input-wrapper .search-toggler {
  background-color: #9055fd !important
}

[dir=rtl] .navbar.bg-primary.layout-navbar .navbar-search-wrapper .search-toggler > i:before {
  transform: scaleX(-1) !important
}

.navbar.bg-primary .navbar-brand, .navbar.bg-primary .navbar-brand a {
  color: #fff
}

.navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus, .navbar.bg-primary .navbar-brand a:hover, .navbar.bg-primary .navbar-brand a:focus {
  color: #fff
}

.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon, .navbar.bg-primary .navbar-search-wrapper .search-input {
  color: #e8dbff
}

.navbar.bg-primary .search-input-wrapper .search-input, .navbar.bg-primary .search-input-wrapper .search-toggler {
  color: #e8dbff;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.navbar.bg-primary .navbar-nav > .nav-link, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e8dbff
}

.navbar.bg-primary .navbar-nav > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link:focus, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:hover, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff
}

.navbar.bg-primary .navbar-nav > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav-item > .nav-link.disabled, .navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #c5a5fe !important
}

.navbar.bg-primary .navbar-nav .show > .nav-link, .navbar.bg-primary .navbar-nav .active > .nav-link, .navbar.bg-primary .navbar-nav .nav-link.show, .navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff
}

.navbar.bg-primary .navbar-toggler {
  color: #e8dbff;
  border-color: rgba(255, 255, 255, .15)
}

.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.8)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.navbar.bg-primary .navbar-text {
  color: #e8dbff
}

.navbar.bg-primary .navbar-text a {
  color: #fff
}

.navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff
}

.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, .15)
}

.menu.bg-primary {
  background-color: #9055fd !important;
  color: #e8dbff
}

.menu.bg-primary .menu-link, .menu.bg-primary .menu-horizontal-prev, .menu.bg-primary .menu-horizontal-next {
  color: #e8dbff
}

.menu.bg-primary .menu-link:hover, .menu.bg-primary .menu-link:focus, .menu.bg-primary .menu-horizontal-prev:hover, .menu.bg-primary .menu-horizontal-prev:focus, .menu.bg-primary .menu-horizontal-next:hover, .menu.bg-primary .menu-horizontal-next:focus {
  color: #fff
}

.menu.bg-primary .menu-link.active, .menu.bg-primary .menu-horizontal-prev.active, .menu.bg-primary .menu-horizontal-next.active {
  color: #fff
}

.menu.bg-primary .menu-item.disabled .menu-link, .menu.bg-primary .menu-horizontal-prev.disabled, .menu.bg-primary .menu-horizontal-next.disabled {
  color: #c5a5fe !important
}

.menu.bg-primary .menu-item.active:not(.open) > .menu-link:not(.menu-toggle), .menu.bg-primary .menu-item.active:not(.open) > .menu-link:not(.menu-toggle)::before {
  color: #fff !important;
  border-color: #fff !important
}

.menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(270deg, #9359fd 0%, #c6a7fe 100%)
}

.menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle), .menu.bg-primary.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle)::before {
  background: #f2ebff;
  color: #9359fd !important
}

.menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle {
  background: linear-gradient(270deg, #9359fd 0%, #c6a7fe 100%)
}

.menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle, .menu.bg-primary.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle:after {
  color: #fff
}

.menu.bg-primary .menu-inner-shadow {
  background: linear-gradient(#9055fd 5%, rgba(144, 85, 253, 0.75) 45%, rgba(144, 85, 253, 0.2) 80%, transparent)
}

.menu.bg-primary .menu-text {
  color: #fff
}

.menu.bg-primary .menu-header {
  color: #d2baff
}

.menu.bg-primary hr, .menu.bg-primary .menu-divider, .menu.bg-primary .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(255, 255, 255, .15) !important
}

.menu.bg-primary .menu-block::before {
  background-color: #d2baff
}

.menu.bg-primary .ps__thumb-y, .menu.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, .5910478431) !important
}

.footer.bg-primary {
  color: #e8dbff
}

.footer.bg-primary .footer-link {
  color: #e8dbff
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #e8dbff
}

.footer.bg-primary .footer-link.disabled {
  color: #c5a5fe !important
}

.footer.bg-primary .footer-text {
  color: #fff
}

.footer.bg-primary .show > .footer-link, .footer.bg-primary .active > .footer-link, .footer.bg-primary .footer-link.show, .footer.bg-primary .footer-link.active {
  color: #fff
}

.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, .15)
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:focus:not(:placeholder-shown) ~ label, .form-floating > .form-select:focus ~ label, .form-floating > .form-select:focus:not(:placeholder-shown) ~ label {
  color: #9055fd
}

.form-floating-focused {
  background-color: #9055fd
}

.form-floating-outline :not(select):focus + label, .form-floating-outline :not(select):focus + span {
  color: #9055fd
}

.form-floating-outline label::after, .form-floating-outline > span::after {
  background: #fff
}

.form-floating-outline label.bg-body::after, .form-floating-outline > span.bg-body::after {
  background: #f4f5fa !important
}

.svg-illustration svg {
  fill: #9055fd
}

.jstree .jstree-children .jstree-wholerow-hovered {
  background-color: rgba(58, 53, 65, .04)
}

.jstree .jstree-children .jstree-wholerow-clicked {
  background: rgba(144, 85, 253, .08)
}

.card.card-border-shadow-primary::after {
  border-bottom-color: #d3bbfe
}

.card.card-border-shadow-primary:hover::after {
  border-bottom-color: #9055fd
}

.card.card-hover-border-primary:hover, .card .card-hover-border-primary:hover {
  border-color: #d5befe
}

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #9055fd !important
}

a {
  color: #9055fd
}

a:hover {
  color: #9b66fd
}

.fill-primary {
  fill: #9055fd
}

.noUi-target:not([disabled]) {
  background: rgba(144, 85, 253, .38)
}

.noUi-target:not([disabled]) .noUi-connect {
  background: #9055fd
}

.noUi-target:not([disabled]) .noUi-handle {
  background: #9055fd
}

.noUi-target:not([disabled]) .noUi-handle:hover {
  box-shadow: 0 0 0 8px rgba(144, 85, 253, .16)
}

.noUi-target:not([disabled]) .noUi-handle:active {
  box-shadow: 0 0 0 10px rgba(144, 85, 253, .16)
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #9055fd !important;
  color: #fff !important
}

.select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection {
  border-width: 2px;
  border-color: #9055fd !important
}

.form-floating.select2-focus label {
  color: #9055fd
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #9055fd !important;
  color: #fff !important
}

.tagify--focus {
  border-color: #9055fd !important
}

.tagify--focus ~ label {
  color: #9055fd !important
}

.tagify__dropdown__item--active {
  background: #9055fd !important
}

table.dataTable.dtr-column > tbody > tr > td.control:before, table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: #9055fd;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(58, 53, 65, .8)
}

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
  background: #9055fd !important;
  color: #fff !important
}

.bootstrap-select .dropdown-toggle.show {
  border-width: 2px;
  border-color: #9055fd
}

.form-floating:has(.bootstrap-select .dropdown-toggle.show) label {
  color: #9055fd
}

.form-floating.form-floating-bootstrap-select label.form-floating-bootstrap-select-label {
  color: #9055fd
}

.datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td .focused, .datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  background: #9055fd !important;
  color: #fff !important
}

.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  color: #9055fd !important;
  background: #f2ebff !important
}

.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
  background: #deccff !important
}

.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
  background: rgba(242, 235, 255, .5) !important;
  color: rgba(144, 85, 253, .5) !important
}

.datepicker table tr td.today:not(.active) {
  box-shadow: 0 0 0 1px #9055fd inset
}

.flatpickr-day.today {
  border-color: #9055fd
}

.flatpickr-day.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
  color: #9055fd !important;
  background: #f2ebff !important;
  border-color: #f2ebff !important
}

.flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.week.selected {
  color: #fff !important;
  background: #9055fd !important;
  border-color: #9055fd !important
}

.daterangepicker td.active:not(.off) {
  background: #9055fd !important;
  color: #fff
}

.daterangepicker .start-date:not(.end-date):not(.off), .daterangepicker .end-date:not(.start-date):not(.off) {
  background-color: #9055fd;
  color: #fff;
  border: 0 !important
}

.daterangepicker .start-date:not(.end-date):not(.off):hover, .daterangepicker .end-date:not(.start-date):not(.off):hover {
  background-color: #9055fd !important
}

.daterangepicker .input-mini.active {
  border-color: #9055fd !important
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  color: #9055fd !important;
  background-color: #f2ebff !important
}

.ranges li.active {
  color: #fff !important;
  background-color: #9055fd !important
}

li.ui-timepicker-selected, .ui-timepicker-list .ui-timepicker-selected:hover {
  color: #fff !important;
  background: #9055fd !important
}

.ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar button:hover, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #9055fd !important
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #9055fd !important
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #9055fd !important
}

.tt-suggestion:active, .tt-cursor {
  background: #9055fd !important;
  color: #fff !important
}

.dropzone.dz-drag-hover {
  border-color: #9055fd !important
}

.swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #9055fd !important
}

.sk-primary.sk-plane, .sk-primary .sk-chase-dot:before, .sk-primary .sk-bounce-dot, .sk-primary .sk-wave-rect, .sk-primary.sk-pulse, .sk-primary .sk-swing-dot, .sk-primary .sk-circle-dot:before, .sk-primary .sk-circle-fade-dot:before, .sk-primary .sk-grid-cube, .sk-primary .sk-fold-cube:before {
  background-color: #9055fd
}

.plyr input[type=range]::-ms-fill-lower {
  background: #9055fd !important
}

.plyr input[type=range]:active::-webkit-slider-thumb {
  background: #9055fd !important
}

.plyr input[type=range]:active::-moz-range-thumb {
  background: #9055fd !important
}

.plyr input[type=range]:active::-ms-thumb {
  background: #9055fd !important
}

.plyr--video .plyr__control.plyr__control--overlaid, .plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__control[aria-expanded=true], .plyr--video .plyr__controls button:hover {
  background: #9055fd !important;
  color: #fff !important
}

.plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__control[aria-expanded=true], .plyr--audio .plyr__controls button:hover {
  background: #9055fd !important;
  color: #fff !important
}

.plyr__play-large {
  background: #9055fd !important;
  color: #fff !important
}

.plyr__progress--played, .plyr__volume--display {
  color: #9055fd !important
}

.plyr--full-ui input[type=range] {
  color: #9055fd !important
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #9055fd !important
}

.fc .fc-event-primary:not(.fc-list-event) {
  background-color: #eee6ff !important;
  color: #9055fd !important
}

.fc .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #9055fd !important
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #9055fd;
  color: #9055fd
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):not(:last-child) {
  border-right: 1px solid #9055fd !important
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: rgba(144, 85, 253, .05) !important;
  border-color: #9055fd;
  color: #9055fd
}

.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):active, .fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active {
  background-color: rgba(144, 85, 253, .1) !important;
  border-color: #9055fd !important;
  color: #9055fd
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step-line, .swal2-progress-steps[class] .swal2-active-progress-step, .swal2-progress-steps[class] .swal2-progress-step {
  background: #9055fd;
  color: #fff
}

.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #eee6ff
}

.pcr-app .pcr-type.active, .pcr-app .pcr-save {
  background: #9055fd !important
}

.icon-card.active {
  outline: 1px solid #9055fd
}

.icon-card.active i, .icon-card.active svg {
  color: #9055fd
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .line {
  border-color: #f2ebff
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .line:before {
  background-color: #deccfe
}

.bs-stepper:not(.wizard-icons) .bs-stepper-header .step:not(.active) .bs-stepper-circle {
  border: 3px solid #f2ebff
}

.bs-stepper.wizard-icons .step.crossed .bs-stepper-label, .bs-stepper.wizard-icons .step.active .bs-stepper-label {
  color: #9055fd
}

.bs-stepper .step.active .bs-stepper-circle {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid #9055fd;
  color: #9055fd
}

.bs-stepper .step.active .bs-stepper-icon i {
  color: #9055fd !important
}

.bs-stepper .step.active .bs-stepper-icon svg {
  fill: #9055fd !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-circle {
  background-color: #9055fd !important;
  color: #fff !important;
  border-color: #9055fd !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-circle i {
  visibility: visible
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon svg {
  fill: #9055fd !important
}

.bs-stepper .step.crossed .step-trigger .bs-stepper-icon i {
  color: #9055fd !important
}

.bs-stepper .step.crossed + .line {
  border-color: #9055fd
}

.bs-stepper .step.crossed + .line svg {
  fill: #9055fd
}

.bs-stepper .step.crossed + .line i {
  color: #9055fd
}

.bs-stepper .step.crossed + .line:before {
  background-color: #9055fd
}

.app-chat .sidebar-body .chat-contact-list li.active {
  background-color: #9055fd
}

.app-chat .app-chat-history .chat-history .chat-message.chat-message-right .chat-message-text {
  background-color: #9055fd !important
}

.navbar.landing-navbar .navbar-nav .show > .nav-link, .navbar.landing-navbar .navbar-nav .active > .nav-link, .navbar.landing-navbar .navbar-nav .nav-link.show, .navbar.landing-navbar .navbar-nav .nav-link.active, .navbar.landing-navbar .navbar-nav .nav-link:hover {
  color: #9055fd !important
}

.landing-features .features-icon-wrapper .features-icon-box .features-icon {
  border: 2px solid rgba(144, 85, 253, .2)
}

.landing-features .features-icon-wrapper .features-icon-box:hover .features-icon {
  background-color: rgba(144, 85, 253, .05)
}

.bg-navbar-theme {
  color: #544f5a
}

.layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar, .layout-horizontal .bg-navbar-theme.layout-navbar {
  background-color: rgba(255, 255, 255, .9) !important
}

.layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar .search-input-wrapper .search-input, .layout-navbar-fixed .window-scrolled .bg-navbar-theme.layout-navbar .search-input-wrapper .search-toggler, .layout-horizontal .bg-navbar-theme.layout-navbar .search-input-wrapper .search-input, .layout-horizontal .bg-navbar-theme.layout-navbar .search-input-wrapper .search-toggler {
  background-color: #fff !important
}

[dir=rtl] .bg-navbar-theme.layout-navbar .navbar-search-wrapper .search-toggler > i:before {
  transform: scaleX(-1) !important
}

.bg-navbar-theme .navbar-brand, .bg-navbar-theme .navbar-brand a {
  color: #544f5a
}

.bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus, .bg-navbar-theme .navbar-brand a:hover, .bg-navbar-theme .navbar-brand a:focus {
  color: #544f5a
}

.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon, .bg-navbar-theme .navbar-search-wrapper .search-input {
  color: #544f5a
}

.bg-navbar-theme .search-input-wrapper .search-input, .bg-navbar-theme .search-input-wrapper .search-toggler {
  color: #544f5a;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.bg-navbar-theme .navbar-nav > .nav-link, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link {
  color: #544f5a
}

.bg-navbar-theme .navbar-nav > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link:focus, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:hover, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #544f5a
}

.bg-navbar-theme .navbar-nav > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav-item > .nav-link.disabled, .bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #98959c !important
}

.bg-navbar-theme .navbar-nav .show > .nav-link, .bg-navbar-theme .navbar-nav .active > .nav-link, .bg-navbar-theme .navbar-nav .nav-link.show, .bg-navbar-theme .navbar-nav .nav-link.active {
  color: #544f5a
}

.bg-navbar-theme .navbar-toggler {
  color: #544f5a;
  border-color: rgba(84, 79, 90, .075)
}

.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(137, 134, 141, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.bg-navbar-theme .navbar-text {
  color: #544f5a
}

.bg-navbar-theme .navbar-text a {
  color: #544f5a
}

.bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #544f5a
}

.bg-navbar-theme hr {
  border-color: rgba(84, 79, 90, .075)
}

.layout-horizontal .layout-navbar {
  box-shadow: 0 1px 0 #e7e7e8
}

.layout-navbar-fixed .layout-page:not(.window-scrolled) .layout-navbar.navbar-detached {
  background: #f4f5fa
}

.bg-menu-theme {
  background-color: #f4f5fa !important;
  color: #544f5a
}

.bg-menu-theme .menu-link, .bg-menu-theme .menu-horizontal-prev, .bg-menu-theme .menu-horizontal-next {
  color: #544f5a
}

.bg-menu-theme .menu-link:hover, .bg-menu-theme .menu-link:focus, .bg-menu-theme .menu-horizontal-prev:hover, .bg-menu-theme .menu-horizontal-prev:focus, .bg-menu-theme .menu-horizontal-next:hover, .bg-menu-theme .menu-horizontal-next:focus {
  color: #544f5a
}

.bg-menu-theme .menu-link.active, .bg-menu-theme .menu-horizontal-prev.active, .bg-menu-theme .menu-horizontal-next.active {
  color: #544f5a
}

.bg-menu-theme .menu-item.disabled .menu-link, .bg-menu-theme .menu-horizontal-prev.disabled, .bg-menu-theme .menu-horizontal-next.disabled {
  color: #94919a !important
}

.bg-menu-theme .menu-item.active:not(.open) > .menu-link:not(.menu-toggle), .bg-menu-theme .menu-item.active:not(.open) > .menu-link:not(.menu-toggle)::before {
  color: #fff !important;
  border-color: #fff !important
}

.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
  background: linear-gradient(270deg, #9055fd 0%, #c4a5fe 100%)
}

.bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle), .bg-menu-theme.menu-horizontal .menu-sub > .menu-item.active > .menu-link:not(.menu-toggle)::before {
  background: #f2ebff;
  color: #9055fd !important
}

.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle {
  background: linear-gradient(270deg, #9055fd 0%, #c4a5fe 100%)
}

.bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle, .bg-menu-theme.menu-horizontal .menu-inner > .menu-item.active > .menu-link.menu-toggle:after {
  color: #fff
}

.bg-menu-theme .menu-inner-shadow {
  background: linear-gradient(#f4f5fa 5%, rgba(244, 245, 250, 0.75) 45%, rgba(244, 245, 250, 0.2) 80%, transparent)
}

.bg-menu-theme .menu-text {
  color: #544f5a
}

.bg-menu-theme .menu-header {
  color: #7c7982
}

.bg-menu-theme hr, .bg-menu-theme .menu-divider, .bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(0, 0, 0, 0) !important
}

.bg-menu-theme .menu-block::before {
  background-color: #7c7982
}

.bg-menu-theme .ps__thumb-y, .bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(84, 79, 90, .2152611765) !important
}

@media (min-width: 1200px) {
  .layout-menu-collapsed.layout-menu-hover .bg-menu-theme {
    box-shadow: 0 .625rem .875rem rgba(58, 53, 65, .12)
  }
}

.bg-menu-theme.menu-horizontal {
  background-color: rgba(255, 255, 255, .9) !important
}

.bg-menu-theme.menu-horizontal .menu-inner .menu-item:not(.menu-item-closing) > .menu-sub {
  background: #fff
}

.bg-footer-theme {
  color: #9055fd
}

.bg-footer-theme .footer-link {
  color: #9055fd
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #9055fd
}

.bg-footer-theme .footer-link.disabled {
  color: #b895fc !important
}

.bg-footer-theme .footer-text {
  color: #544f5a
}

.bg-footer-theme .show > .footer-link, .bg-footer-theme .active > .footer-link, .bg-footer-theme .footer-link.show, .bg-footer-theme .footer-link.active {
  color: #544f5a
}

.bg-footer-theme hr {
  border-color: rgba(84, 79, 90, .0769076471)
}
